.entry-content img {
    margin: 0 0 1.5em 0;
}

.alignleft,
img.alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
}

.alignright,
img.alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
}

.aligncenter,
img.aligncenter {
    display: block;
    clear: both;
    margin-right: auto;
    margin-left: auto;
}

.wp-caption {
    margin-bottom: 1.5em;
    text-align: center;
}

.wp-caption img {
    margin: 0;
    padding: 0;
    border: 0 none;
}

.wp-caption p.wp-caption-text {
    margin: 0;
}

.wp-smiley {
    max-height: 1em;
    margin: 0 !important;
}

.gallery dl {
    margin: 0;
}

.gallery-caption {
    margin: -1.5em 0 0 0;
}

blockquote.left {
    float: left;
    width: 33%;
    margin-right: 20px;
    margin-left: 0;
    text-align: right;
}

blockquote.right {
    float: right;
    width: 33%;
    margin-right: 0;
    margin-left: 20px;
    text-align: left;
}
