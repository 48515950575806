.main-content {
	@include flex-container;
	padding: 6rem 0;
}

.block {
	&.background-white {
		background-color: $white;
	}

	&.background-grey {
		background-color: $grey;
	}

	&.background-lightgrey {
		background-color: $grey-light;
	}

	&.background-darkgrey {
		background-color: $grey-dark;
	}

	&.background-orange {
		background-color: $orange;
	}
}