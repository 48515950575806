%heading-text {
	font-family: $heading-text;
	line-height: 1.2;
	font-weight: 500;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    @extend %heading-text;
}

h1,
.h1 {
	font-size: 5.2rem;
}

h2,
.h2 {

}

h3,
.h3 {

}

h4,
.h4 {

}

h5,
.h5 {

}

p {
    margin: 1rem 0;
}

a,
a:hover,
a:active,
a:focus,
a:visited {

}

a:hover {

}
