.footer {
	border-top: 1px solid $grey-light;
	background-color: $grey-lighter;

	.footer__inner {
		@include flex-container;
		justify-content: space-between;
		margin: 4rem auto;
		font-size: 1.4rem;

		@include grid-media($small) {
			flex-direction: column;
		}
	}

	.footer-navigation {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		@include grid-media($small) {
			display: none;
		}
	}

	.footer__copyright {
		background-color: $white;
		padding: 1rem 0;
		text-align: center;
		font-size: 1.2rem;
		color: $grey;
	}
}