.block--two-column {
	&.size-default {
		.block__inner {
			@include flex-container;

			@include grid-media($small) {
				flex-direction: column;
			}
		}
	}

	&.size-fullwidth {
		.block__inner {
			display: flex;
			width: 100%;

			@include grid-media($small) {
				flex-direction: column;
			}

			.column--image {
				max-height: 50rem;

				@include grid-media($small) {
					margin: 0;
				}
			}

			.column--text {
				@include grid-media($small) {
					padding: 2rem;
				}
			}
		}
	}

	.column {
		width: calc(50% - 1rem);
		margin-left: 1rem;
		margin-right: 1rem;
		float: left;

		@include grid-media($small) {
			width: 100%;
			margin: 0;
		}

		&.column--image {
			width: 50%;
			margin: 0;

			@include grid-media($small) {
				width: 100%;
				margin-top: 2rem;
				margin-bottom: 2rem;
			}

			img {
				float: left;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&.column--text {
			padding: 2rem;
			display: flex;
			justify-content: center;
			flex-direction: column;

			@include grid-media($small) {
				padding: 2rem 0;
			}
		}

		&.column--counter {
			.count-in-view {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.count-item {
				background-color: $orange;
				border-radius: 50%;
				width: 100%;
				max-width: 20rem;
				height: 20rem;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				margin: 0 2rem;
				color: $white;
				padding: 2rem;
				text-align: center;

				@include grid-media($small) {
					width: 100%;
					max-width: 12rem;
					height: 12rem;
					margin: 1rem;
				}
			}

			.count {
				font-size: 3rem;

				@include grid-media($small) {
					font-size: 2rem;
				}
			}

			.text {
				text-transform: uppercase;
				font-size: 2rem;

				@include grid-media($small) {
					font-size: 1.4rem;
				}
			}
		}
	}
}