// Variables
$white: #fff;
$black: #333333;

$orange: #ff931a;
$grey-lighter: #f9f9f9;
$grey-light: #e1e1e1;
$grey: #a9a9a9;
$grey-dark: #666666;

// Fonts
$nav-text: 'Roboto Condensed', Helvetica, Arial, Lucida,sans-serif;
$heading-text: 'Oswald', Helvetica, Arial, Lucida,sans-serif;
$body-text: 'Roboto Condensed', Helvetica, Arial, Lucida,sans-serif;

// Grid
$outer-padding: 2rem;
$max-width: 1100px !default;

$neat-grid: (
  columns: 12,
  gutter: 20px,
);

//Breakpoints
$small: (
  columns: 12,
  gutter: 50px,
  media: "(max-width: 600px)",
);

$medium: (
  columns: 12,
  gutter: 50px,
  media: "(max-width: 900px)",
);

$large: (
  columns: 12,
  gutter: 50px,
  media: "(max-width: 1200px)",
);

body {
    @include grid-media($small) {
        /* Small */
    }

    @include grid-media($medium) {
        /* Medium */
    }

    @include grid-media($large) {
        /* Large */
    }
}
