.block--form {
	.block__inner {
		@include flex-container;
	}

	.newsletter {
		width: 100%;
		text-align: center;

		.field {
			padding: 1rem;
			min-width: 30rem;
			border: 1px solid $grey-light;

			@include grid-media($small) {
				min-width: 100%;
			}
		}

		.btn-wanfs {
			padding: 1rem 2rem .7rem 2rem;
		}
	}

	.donation {
		width: 100%;

		.pronamic-pay-form-title {
			display: none;
		}

		.pronamic-pay-amount {
			input {
				padding: 1rem;
				border: 1px solid $grey-light;
			}
		}

		.pronamic-pay-form {
			fieldset {
				border: 0;
				padding: 0;
				margin: 0;
				width: 50%;
				float: left;
			}

			legend {
				font-size: 2rem;
				font-weight: bold;
				margin: 0 0 2rem 0;
				padding-top: 1rem;
			}
		}

		.pronamic-pay-form-row  {
			label {
				display: none;
			}

			input {
				padding: 1rem;
				border: 1px solid $grey-light;
				width: 100%;
			}
		}

		.pronamic-pay-btn {
			border: 0;
			font-weight: 900;
			padding: 1rem 2rem;
			margin-top: 1rem;
			margin-bottom: 2rem;
			margin: auto;
			background-color: $orange;
			color: $white;
			float: right;

			&:hover {
				background-color: $black;
			}
		}
	}

	.form {
		width: 100%;
	}
}