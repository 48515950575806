.block--slider {
}

.slider-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 50rem;

	.swiper-wrapper {
		.swiper-slide {
			position: relative;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			width: 80vw;
		}
	}

	.slider-navigation {
		position: absolute;
		z-index: 999;
		width: 90%;
		top: 50%;
		left: 5%;

		.button-prev {
			width: 3.5rem;
			height: 3.5rem;
			float: left;
			display: inline-block;
			cursor: pointer;
		}

		.button-next {
			width: 3.5rem;
			height: 3.5rem;
			float: right;
			display: inline-block;
			cursor: pointer;
		}

		.swiper-button-disabled {
			opacity: .4;
			cursor: default;
		}
	}
}