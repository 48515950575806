.header {
	background-color: $black;

	.header__inner {
		@include flex-container;
		align-items: center;
		justify-content: space-between;

		.navigation {
			flex-grow: 2;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			@include grid-media($small) {
				display: none;
			}

			ul {
				list-style: none;
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}

			li {
				margin: 0 2rem;
			}

			a {
				color: $white;
				text-transform: uppercase;

				&:hover {
					color: $orange;
				}
			}

			.lang_selector {
				&:before {
					content: '';
					margin-right: 2rem;
					border-right: 2px solid $white;
					color: $white;
				}

				a {
					padding: 0 .5rem;
				}

				.active {
					color: $grey-dark;
					cursor: default;
				}
			}
		}

		.logo {
			padding: 1rem 0;

			img {
				height: 3rem;
				width: auto;
			}
		}

		.mobile-menu {
			flex-grow: 2;
			display: none;

			@include grid-media($small) {
				display: block;
			}

			a {
				color: $white;
			}
		}
	}

	.header__mobile {
		@include flex-container;
		align-items: center;
		justify-content: space-between;
		display: none;

		@include grid-media($small) {
			display: flex;
		}
	}
}

.block--hero {
	height: 60rem;
	background-size: cover;
	position: relative;
	overflow: hidden;
	//background: linear-gradient( 0deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,.05) 40%);
	display: flex;
	color: $white;
	text-transform: uppercase;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	h1 {
		padding: 2rem;
	}

	.btn-wanfs {
		margin: 3rem auto;
	}

	.anbi-logo {
		position: absolute;
		right: 2rem;
		bottom: 2rem;
	}
}