.block--partners-feed {
	padding: 3rem 0;
	
	.block__inner {
		@include flex-container;
		align-items: center;
		justify-content: center;

		@include grid-media($small) {
			flex-direction: column;
		}

		.partners-container {
			position: relative;
			overflow: hidden;
			width: 100%;
		}

		.partner {
			margin: 2rem;

			img {
				float: left;
				height: 6rem;
				-webkit-filter: grayscale(100%);
				filter: grayscale(100%);
				transition: .4s all;
			}

			&:hover {
				img {
					-webkit-filter: grayscale(0%);
					filter: grayscale(0%);
				}
			}
		}
	}
}