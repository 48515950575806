.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 30rem;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: $white; /* A background-color is required */
  min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: flex;
}

.header__mobile {
  justify-content: center;
  align-items: center;
  background-color: $orange;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 2rem auto;
  }

  a {
    color: $white;
    text-transform: uppercase;

    &:hover {
      color: $black;
    }
  }
}