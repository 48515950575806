img.gform_ajax_spinner {
    display: none!important;
}

.gform_wrapper {
    .validation_error {
    }

    ul {
        list-style: none;
    }

    .gform_body {
        .gfield {
            .gfield_label {
                display: none;
            }

            input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
                width: 100%;
                max-width: 100% !important;
                border: 1px solid $grey-light;
                background-color: $white;
                padding: 1rem;
            }

            textarea {
                width: 100%;
                max-width: 100% !important;
                border: 1px solid $grey-light;
                background-color: $white;
                padding: 1rem;
            }

            select {
                width: 100% !important;
                border: 1px solid $grey-light;
                background: $white url('../img/icon-arrow-down.svg') right 2rem center no-repeat;
                padding: 1rem;
            }
        }
    }

    .gfield_error {
        input {
        }
    }

    .validation_message {
    }

    .gform_footer {
        input[type=submit] {
            border: 0;
            padding: 2rem;
            color: $white;
            background-color: $orange;
            font-weight: 600;
            cursor: pointer;

            &:hover {
                background-color: $black;
            }
        }
    }
}
