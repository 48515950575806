.block--image-text {
	.block__inner {
		@include flex-container;
		justify-content: center;
		align-items: flex-start;

		@include grid-media($small) {
			flex-direction: column;
		}
	}

	.column {
		@include grid-column(3);

		@include grid-media($small) {
			@include grid-column(12);
		}

		.image {
			img {
				width: 100%;
				height: 24rem;
				object-fit: cover;
			}
		}

		.text {
			text-align: center;
		}
	}
}