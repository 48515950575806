@mixin outer-container($local-max-width: $max-width, $local-outer-padding: $outer-padding) {
    width: $local-max-width + (2 * $local-outer-padding);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $local-outer-padding;
    padding-right: $local-outer-padding;
}

@mixin flex-container($local-max-width: $max-width, $local-outer-padding: $outer-padding) {
    width: $local-max-width;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding-left: $local-outer-padding;
    padding-right: $local-outer-padding;
}

@mixin omega-reset($nth) {
    &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
    &:nth-child(#{$nth}+1) { clear: none; }
}

@mixin spread-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin absolute-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin image-replace {
    text-indent: 102%;
    white-space: nowrap;
    overflow: hidden;
}

@mixin hide-visually {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin media-object( $stretch: '.content' ) {
    display: flex;
    align-items: flex-start;

    & > #{$stretch} {
        flex: 1;
    }
}

@mixin sticky-footer( $stretch: '.content' ) {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    & > #{$stretch} {
        flex: 1;
    }
}

@mixin ratio-box ( $ratio: 1/1, $inner: '.inner' ) {
    overflow: hidden;
    position: relative;

    &:before {
        content: "";
        display: block;
        height: 0;
        padding-top: (1 / $ratio) * 100%;
    }

    & > #{$inner} {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
