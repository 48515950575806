.block--video {
	&.size-default {
		.block__inner {
			@include flex-container;
		}
	}

	&.size-fullwidth {
		.block__inner {
			display: flex;
			width: 100%;
		}
	}

	.videolink {
		height: 40rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		background-position: center center;
		outline: 0;

		@include grid-media($small) {
			height: 25rem;
		}

		img {
			padding: 2rem 5rem;
			background-color: $orange;
			opacity: .8;
		}
	}
}