// Imports
@import '../../bower/bourbon/app/assets/stylesheets/bourbon';
@import '../../bower/neat/core/neat';
@import '../../bower/normalize.css/normalize';
@import '../../bower/magnific-popup/dist/magnific-popup';

// Variables
@import "utils/vars";

// Imports
@import 'utils/functions';
@import 'utils/mixins';

@import 'base/typography';
@import 'base/wp-default';

@import 'layout/header';
@import 'layout/footer';

@import 'pages/page';
@import 'pages/archive';
@import 'pages/single';
@import 'pages/home';

@import 'components/button';
@import 'components/form';
@import 'components/hamburger';
@import 'components/mobilemenu';

@import 'blocks/form';
@import 'blocks/image-text';
@import 'blocks/maps';
@import 'blocks/one-column';
@import 'blocks/two-column';
@import 'blocks/partners-feed';
@import 'blocks/slider';
@import 'blocks/video';

//Sitewide CSS
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: 10px;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    font-family: $body-text;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.6;
    width: 100%;
    min-height: 100%;
    color: $black;
}

img {
    max-width: 100%;
    height: auto;
}

input[type=text],
button,
select,
textarea {
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.clearfix {
    @include clearfix;
}

a {
    color: $black;
    text-decoration: none;
}