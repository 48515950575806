.btn-wanfs {
	border: 0;
	font-size: 2rem;
	font-weight: 900;
	text-transform: uppercase;
	padding: 1rem 2rem;
	margin-top: 1rem;
	margin-bottom: 2rem;
	display: table;
	margin: auto;
}

.btn-orange {
	background-color: $orange;
	color: $white;

	&:hover {
		background-color: darken($orange, 5%);
	}
}

.btn-black {
	background-color: $black;
	color: $white;

	&:hover {
		background-color: lighten($black, 5%);
	}
}